<template>
  <div id="app">
      <Search />
      <Results />
  </div>
</template>

<script>
  import Search from './components/Search';
  import Results from './components/Results';

  export default {
    name: 'app',
    components: {
      Results,
      Search
    }
  };
</script>

<style lang="stylus">
  #app {
    display: block;
    font-family: 'Avenir', Helvetica, sans-serif;
    font-smoothing: antialised;
    margin 10px
  }
</style>