import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import _ from 'lodash';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  SET_LOADING = 'SET_LOADING',
  SET_RESULTS = 'SET_RESULTS',
  RESET_RESULTS = 'RESET_RESULTS',
  SET_ERROR = 'SET_ERROR',
  RESET_ERROR = 'RESET_ERROR',
  SET_DATA = 'SET_DATA',
  SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES',
  SET_SUBJECTS = 'SET_SUBJECTS',
  SET_SEARCH_DOCUMENT_TYPE = 'SET_SEARCH_DOCUMENT_TYPE',
  SET_SEARCH_SUBJECT = 'SET_SEARCH_SUBJECT',
  SET_SORT = 'SET_SORT',
  RESET_SORT = 'RESET_SORT';

export default new Vuex.Store({
  state: {
    searchQuery: '',
    loading: false,
    results: [],
    error: null,
    document_types: null,
    subjects: null,
    searchDocumentType: '',
    searchSubject: '',
    sort: {type: null, way: null}
  },
  mutations: {
    [SET_SEARCH_QUERY]: (state, searchQuery) => state.searchQuery = searchQuery,
    [SET_LOADING]: (state, loading) => state.loading = loading,
    [SET_RESULTS]: (state, results) => state.results = results,
    [RESET_RESULTS]: state => state.results = null,
    [SET_ERROR]: (state, error) => state.error = error,
    [RESET_ERROR]: state => state.error = null,
    [SET_DATA]: (state, data) => state.data = data,
    [SET_DOCUMENT_TYPES]: (state, document_types) => state.document_types = document_types,
    [SET_SUBJECTS]: (state, subjects) => state.subjects = subjects,
    [SET_SEARCH_DOCUMENT_TYPE]: (state, document_type) => state.searchDocumentType = document_type,
    [SET_SEARCH_SUBJECT]: (state, subject) => state.searchSubject = subject,
    [SET_SORT]: (state, sort) => state.sort = sort,
    [RESET_SORT]: state => state.sort = null
  },
  actions: {
    loadData({ commit }) {
      // commit(SET_LOADING, true);
      axios.get('https://s3.amazonaws.com/nature-quebec/data.json').then((response) => {
        /*_.each(response.data, (item) => { //todo make this pretty using a better regex
          item.fichier = item.fichier.replace(/é/g, 'e');
          item.fichier = item.fichier.replace(/è/g, 'e');
          item.fichier = item.fichier.replace(/ê/g, 'e');
          item.fichier = item.fichier.replace(/à/g, 'a');
          item.fichier = item.fichier.replace(/ô/g, 'o');
          item.fichier = item.fichier.replace(/î/g, 'i');
        });*/
        commit(SET_DATA, response.data);

        commit(SET_SORT, { type: 'date_publication', way: 'desc' });
        // commit(SET_RESULTS, _.orderBy(state.data, state.sort.type, state.sort.way));

        commit('SET_DOCUMENT_TYPES', _.sortBy(_.compact(_.uniq(_.map(response.data, 'type_document')))));
        commit('SET_SUBJECTS', _.sortBy(_.compact(_.uniq(_.map(response.data, 'sujet')))));

        // commit(SET_LOADING, false);
      }).catch((error) => {
        commit(SET_ERROR, error.stack);
        // commit(SET_LOADING, false);
      });
    },
    setSearchQuery({ commit }, searchQuery) {
      commit(SET_SEARCH_QUERY, searchQuery.toLowerCase());
    },
    setSearchSubject({ commit }, subject) {
      commit(SET_SEARCH_SUBJECT, subject);
    },
    setSearchDocumentType({ commit }, document_type) {
      commit(SET_SEARCH_DOCUMENT_TYPE, document_type);
    },
    setSort({ commit, state }, sort_type) {
      if (state.sort && state.sort.type === sort_type) {
        if (state.sort.way === 'desc') {
          commit(SET_SORT, {type: sort_type, way: 'asc'});
        } else {
          commit(SET_SORT, {type: sort_type, way: 'desc'});
        }
      } else {
        commit(SET_SORT, {type: sort_type, way: 'desc'});
      }
    },
    async search({ commit, state }) {
      commit(SET_LOADING, true);
      if (state.searchQuery != '' || state.searchDocumentType != '' || state.searchSubject != '') {
        const results = await _.filter(state.data, (d) => {
          return (d.titre.toLowerCase().indexOf(state.searchQuery) > -1 && d.sujet.indexOf(state.searchSubject) > -1 && d.type_document.indexOf(state.searchDocumentType) > -1);
        });
        commit(SET_RESULTS, _.orderBy(results, state.sort.type, state.sort.way));
      } else {
        commit(RESET_RESULTS);
        commit(SET_RESULTS, _.orderBy(state.data, state.sort.type, state.sort.way));
      }
      commit(SET_LOADING, false);
    }
  }
});