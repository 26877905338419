import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

new Vue({
  store,
  render: h => h(App),
  created() {
    this.$store.dispatch('loadData')
  }
}).$mount('#app')
