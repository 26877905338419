<template>
  <div class="resultats">
    <div v-if="loading">Loading</div>
    <div v-for="(result, index) in results" :key="`result.-${index}`" class="file-infos">
      <div class="infos">
        <p class="date">Date de publication: {{ result.date_publication | moment("DD/MM/YYYY") }}</p>
        <a class="title" :href="result.fichier" target="_blank"><h2>{{ result.titre }}</h2></a>
        <p class="type"><i>{{ result.type_document }}</i></p>
        <p class="project"><i class="fas fa-leaf"></i> {{ result.projet }}</p>



        <p class="sujet-commission">
          <span><b>Sujet:</b> {{ result.sujet }}</span>
          <span><b>Commission:</b> {{ result.commission }}</span>
        </p>
      </div>
      <a class="download" :href="result.fichier" target="_blank">
          <div class="download-text">
            <i class="far fa-file-pdf"></i>
            <p>Consulter le document<br><span>({{ result.langue }})</span></p>
          </div>
      </a>


    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "results",
  computed: mapState(["loading", "results"])
};
</script>

<style>
  .resultats {
    width: 70%;
    float: right;
    padding: 20px;
  }
  .file-infos {
    display: flex;
    margin-bottom: 20px;
    border-radius: 30px;
    border:2px solid #eee;
  }
  .infos {
    flex-basis: 80%;
    padding: 20px;
    border-radius: 30px 0px 0px 30px;
  }
  .infos .date {
    margin:0px;
    font-size: 12px;
    color:#666;
  }
  .infos .title {
    text-decoration: none;
    color:#333;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .infos .project {
    display: inline;
    color:green;
    font-weight: 600;
  }
  .project i {
    font-size: 12px;
  }

  .infos .type {
    margin-bottom: 0px;
  }

  .sujet-commission {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sujet-commission span {
    margin-right: 20px;
  }

  .download {
    display: flex;
    width: 100%;
    flex-basis: 20%;
    padding: 20px;
    background-color: #eee;
    color:#333;
    border-radius: 0px 30px 30px 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .download:hover {
    background-color: green;
    color:white;
  }
  .download .download-text {
    display: table-cell;
    vertical-align: middle;
    flex:0 0 auto;
  }
  .download-text i {
    width: 100%;
    margin-top: 4px;
    font-size: 50px;
  }
  .download-text p {
    margin-bottom: 0px;
  }

  .download-text p span {
    font-size: 13px;
  }
</style>
