<template>

  <div class="filtres">
      <h3>Trouvez une publication</h3>
    <div>
      <label for="search-field">Rechercher:</label>
      <input id="search-field" v-model="query" @input="debouncedSearch" placeholder="Entrez un mot clé">
    </div>

      <label>Tri</label>
      <a href="#" @click="setSort('date_publication'); debouncedSearch();">Date publication</a><br>
      <a href="#" @click="setSort('titre'); debouncedSearch();">Titre</a>

      <label for="document-type">Type de document: </label>
      <select id="document-type" v-model="document_type" @change="debouncedSearch">
        <option value="">Tous les types de documents</option>
        <option value="Actes et cahiers de participant">Actes et cahiers de par ticipant</option>
        <option value="Analyses et textes de références">Analyses et textes de références</option>
        <option value="Bulletins">Bulletins</option>
        <option value="Communiqués">Communiqués</option>
        <option value="Correspondance">Correspondance</option>
        <option value="Dépliants, fiches et feuillets<">Dépliants, fiches et feuillets</option>
        <option value="Lettres d’opinion">Lettres d’opinion</option>
        <option value="Magazine Franc-Vert">Magazine Franc-Vert</option>
        <option value="Matériel audio-visuel">Matériel audio-visuel</option>
        <option value="Mémoires, guides et rapports">Mémoires, guides et rapports</option>
        <option value="Plans de conservation">Plans de conservation</option>
        <option value="Publicités">Publicités</option>
        <option value="Rapports annuels">Rapports annuels</option>
        <option value="Sites web archivés">Sites web archivés</option>
        <option value="Sollicitations">Sollicitations</option>
        <option value="Textes externes">Textes externes</option>
      </select>

      <label for="subject">Sujet: </label>
      <select id="subject" v-model="subject" @change="debouncedSearch">
        <option value="">Tous les sujets</option>
        <option value="Agroalimentaire">Agroalimentaire</option>
        <option value="Agroenvironnement">Agroenvironnement</option>
        <option value="Aires protégées">Aires protégées</option>
        <option value="BSE">BSE</option>
        <option value="Bassins versants">Bassins versants</option>
        <option value="Biens et services environnementaux">Biens et services environnementaux</option>
        <option value="Biodiversité">Biodiversité</option>
        <option value="Bioénergies">Bioénergies</option>
        <option value="Changements climatiques">Changements climatiques</option>
        <option value="Conservation">Conservation</option>
        <option value="Cyanobactéries">Cyanobactéries</option>
        <option value="Développement durable">Développement durable</option>
        <option value="Eaux souterraines">Eaux souterraines</option>
        <option value="Économie écologique">Économie écologique</option>
        <option value="Écotourisme">Écotourisme</option>
        <option value="Élections">Élections</option>
        <option value="Élevage porcin">Élevage porcin</option>
        <option value="Énergies alternatives">Énergies alternatives</option>
        <option value="Enjeux environnementaux">Enjeux environnementaux</option>
        <option value="Éthanol maïs">Éthanol maïs</option>
        <option value="Espèces en péril">Espèces en péril</option>
        <option value="Espèces focales">Espèces focales</option>
        <option value="Filière gaz et pétrole">Filière gaz et pétrole</option>
        <option value="Filière thermique">Filière thermique</option>
        <option value="Filière éolienne">Filière éolienne</option>
        <option value="Forêt boréale">Forêt boréale</option>
        <option value="Forêt publique">Forêt publique</option>
        <option value="Hydroélectricité">Hydroélectricité</option>
        <option value="Ignorer">Ignorer</option>
        <option value="Matières résiduelles">Matières résiduelles</option>
        <option value="Milieux humides">Milieux humides</option>
        <option value="Mines">Mines</option>
        <option value="Multifonctionnalité">Multifonctionnalité</option>
        <option value="Nord québécois">Nord québécois</option>
        <option value="Nucléaire">Nucléaire</option>
        <option value="OGM">OGM</option>
        <option value="Parcs nationaux">Parcs nationaux</option>
        <option value="Pesticides">Pesticides</option>
        <option value="Politiques gouvernementales">Politiques gouvernementales</option>
        <option value="Pollution agricole">Pollution agricole</option>
        <option value="Protection du territoire agricole">Protection du territoire agricole</option>
        <option value="Rivières">Rivières</option>
        <option value="Saint-Laurent">Saint-Laurent</option>
        <option value="Territoires fauniques">Territoires fauniques</option>
        <option value="Transport">Transport</option>
        <option value="Vie démocratique">Vie démocratique</option>
      </select>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: "search",
  computed: {
    ...mapState(["searchQuery", "searchSubject", "searchDocumentType", "sort"]),
    query: {
      get() {
        return this.searchQuery;
      },
      set(val) {
        return this.setSearchQuery(val);
      }
    },
    subject: {
      get() {
        return this.searchSubject;
      },
      set(val) {
        return this.setSearchSubject(val);
      }
    },
    document_type: {
      get() {
        return this.searchDocumentType;
      },
      set(val) {
        return this.setSearchDocumentType(val);
      }
    },
    sort: {
      get() {
        return this.sort;
      },
      set(val) {
        return this.setSort(val);
      }
    }
  },
  methods: {
    ...mapActions(["setSearchQuery", "search", "setSearchSubject", "setSearchDocumentType", "setSort"]),
    debouncedSearch: debounce(function() {
      this.search();
    }, 500)
  }
};
</script>

<style lang="stylus" scoped>
  .filtres {
    display block;
    width: 24%;
    position: fixed;
    padding-left 10px;
  }
input {
  display: block;
  width: 90%;
  font-size: 16px;
  text-align: left;
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  border: 2px solid #eee;
  background-color white;
  box-shadow: none;
}
  select {
    display: block;
    width: 100%;
    font-size: 16px;
    text-align: left;
    padding: 10px 20px 10px 20px;
    border: 2px solid #eee;
    background-color white;
    box-shadow: none;
  }
  label {
    display block
    font-size 18px;
    font-weight 600;
    margin-top 20px;
    margin-bottom 10px;
  }
</style>